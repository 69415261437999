<template>
    <div class="aa-lectura mb-3" v-if="actividadDetalle">
        <div class="aa-lectura-img">
            <img :src="actividadDetalle.portada"  v-on:load="onLoaded" v-show="loaded" />
            <div style="width:100%; height: 327px" v-show="!loaded">
                    <LoadingVue />
            </div>
        </div>
        <div class="aa-lectura-txt aa-text-h2 mb-1 text-start mt-2">
            {{actividadDetalle.titulo}}
        </div>
        <div class="aa-lectura-doc text-start" v-html="actividadDetalle.cuerpo"></div>

        <swiper
                :slidesPerView="1"
                :space-between="0"
                :grabCursor="true"
                :scrollbar="{ hide: false }"
                :breakpoints="{
                    '640': {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    '768': {
                        slidesPerView: 2,
                        spaceBetween: 40,
                    },
                    '1024': {
                        slidesPerView: 3,
                        spaceBetween: 50,
                    },
                }"
                :modules="modules"
                class="default-slider mt-2 mb-3"
                v-if="actividadDetalle.imagenes && actividadDetalle.imagenes.length > 1"
        >
        <swiper-slide v-for="(image, index) in actividadDetalle.imagenes " :key="index">
            <div class="contenedor">
                <div class="img-contenedor">
                    <a :href="image.attributes.url" target="_blank">
                    <img :src="image.attributes.url" alt="IES Fray Bartolomé" title="IES Fray Bartolomé de las Casas">
                    </a>
                </div>
            </div>
        </swiper-slide>
        </swiper>


        <DescargaDocumento :documentos="actividadDetalle.documentos" :titulo="'Documentos'" class="mt-1" />
    </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { defineAsyncComponent, watch } from 'vue'
import useActividadesb from '../composables/useActividadesb'

import { Swiper } from 'swiper/vue/swiper'
import { SwiperSlide } from 'swiper/vue/swiper-slide'
import { Scrollbar } from "swiper"

import 'swiper/modules/scrollbar/scrollbar.min.css'
import 'swiper/swiper.min.css'


export default {
    components: {
        Swiper, 
        SwiperSlide,
        LoadingVue: defineAsyncComponent( () => import('../components/Loading.vue')),
        DescargaDocumento: defineAsyncComponent( () => import('../components/Area/DescargaDocumento.vue')),
    },
    setup(){
        const route = useRoute()
        const { actividadDetalle, loaded,  leeActividad } = useActividadesb()
        leeActividad(route.params.id)
        
        watch(
            () => route.params.id,
            () => leeActividad(route.params.id)
        )

        return{
            onLoaded: () => loaded.value = true,
            loaded,
            actividadDetalle,
            modules: [ Scrollbar ],
        }
    }
}
</script>

<style lang="sass" scoped>
.aa-lectura-img
    max-height: 300px
    overflow: hidden
    margin-top: 96px

.contenedor
    width: 100%
    .img-contenedor
        max-height: 300px
        overflow: hidden

        img
            width: 100%
</style>